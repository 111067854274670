.modal {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  padding: 32px;
  color: #333;
  font-family: 'Inter', sans-serif;
  
  max-width: 50%;


}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .modal {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);  
  }
  .warning {
    display: none;
  }
}

html,
body {
  height: 100% !important;
  width: 100% !important;
  margin: 0px;
}
.outerContainer {

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100% !important;
  width: 100% !important;

}

#root {
  height: 100% !important;
  width: 100% !important;


}
.container {
  padding:32px;

  /* margin-top:  100px; */
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}


.form-signin {
  align-items: center;
  display: flex;
  justify-content: center;

}

.centered {
  margin: 0 auto;
}

p {
    margin: 0px;
}